/* TimelineLoader.css */
.timeline-loader {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .timeline-step {
    display: flex;
    align-items: center;
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }
  
  .timeline-step.active {
    opacity: 1;
  }
  
  .step-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  
  .active .step-indicator {
    border-color: #5a64d7;
    background-color:  #5a64d7;;
  }
  
  .tick {
    color: white;
    font-weight: bold;
  }
  
  .loading-spinner {
    width: 14px;
    height: 14px;
    border: 2px solid white;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .step-label {
    font-size: 14px;
  }

  .timeline-container {
    position: relative;
  }
  
  .timeline-line {
    position: absolute;
    left: 10px;  /* Adjust based on your layout */
    top: 20px;   /* Adjust based on your layout */
    bottom: 20px;
    width: 2px;
    background-color: #ccc;
    z-index: 0;
  }
  
  .timeline-loader {
    position: relative;
    z-index: 1;
  }